angular.module('enervexSalesappApp').factory('LineItemUtil', function LineItemUtil(_) {
	return {
		formatItem: function(item) {
			if (item.itemType == 'existing') {
				item.origin = 'design'
				item.source = {
					exhaust: 0,
					heatrecovery: 0,
					stack: 1,
					supply: 0,
					other: 0
				}
				item.systemSources = [{
					systemId: 1,
					exhaust: 0,
					heatrecovery: 0,
					stack: 1,
					supply: 0,
					other: 0
				}]
				return item
			} else {
				return {
					itemType: "custom",
					quantity: item.quantity,
					uom: "EA",
					origin: "design",
					source: {
						exhaust: 0,
						heatrecovery: 0,
						stack: 1,
						supply: 0,
						other: 0
					},
					customProduct: {
						name: item.customProduct.name,
						price: item.customProduct.price,
						description: item.customProduct.description,
						unit: "EA",
						navId: item.customProduct.navId
					},
					systemSources: [{
						systemId: 1,
						exhaust: 0,
						heatrecovery: 0,
						stack: 1,
						supply: 0,
						other: 0
					}],
					// list: 0,
					// totalList: 0
				}
			}
		}
	}
})